<script setup lang="ts">
  import { useI18n } from 'vue-i18n'
  import MediaGallery from '@/components/MediaGallery.vue'
  import Highlighter from 'vue-highlight-words'
  import SlugsComponent from '@/components/SlugsComponent.vue'
  import RelatedDocuments from '@/components/RelatedDocuments.vue'
  import DocumentDetails from '@/components/DocumentDetails.vue'
  import WebShare from '@/components/WebShare.vue'
  import CopyButton from '@/components/CopyButton.vue'
  import { computed, onMounted, type PropType, ref, toRefs, watch } from 'vue'
  import type MultimediaDocument from '@/store/parser/MultimediaDocument'
  import linkifyHtml from 'linkify-html'
  import type { DirectiveBinding, Ref } from 'vue'
  import { nextTick } from 'vue'
  import type TextDocument from '@/store/parser/TextDocument'
  import { useRoute } from 'vue-router'
  import DockComponent from '@/components/DockComponent.vue'
  import { useCurrentElement, useScroll } from '@vueuse/core'

  const emit = defineEmits<{
    (e: 'close'): void
  }>()

  const i18n = useI18n({ useScope: 'global' })
  const { t } = i18n

  const route = useRoute()
  const article = ref<HTMLElement | null>(null)

  const el = useCurrentElement() as Ref<HTMLElement>
  const { directions } = useScroll(el)
  const showNavbar = ref(true)
  watch(directions, dir => {
    if (dir.bottom) showNavbar.value = false
    if (dir.top) showNavbar.value = true
  })

  import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'
  const { smaller } = useBreakpoints(breakpointsTailwind)
  const isSmallerMd = smaller('sm')

  onMounted(async () => {
    await nextTick()
    if (route.meta.scrollPosition) {
      article.value?.scrollTo({
        ...route.meta.scrollPosition,
        behavior: 'auto'
      })
    }
  })

  const props = defineProps({
    doc: {
      type: Object as PropType<TextDocument | MultimediaDocument>,
      required: true
    },
    searchTerms: {
      type: Array<string>,
      required: false,
      default: () => []
    }
  })
  const { doc, searchTerms } = toRefs(props)

  function getSubtitle(p: string) {
    const subtitleMatch = p.match(/^\s?[-–]\s(.*)\s[-–]\s?$/)
    return subtitleMatch ? subtitleMatch[1] : p
  }

  const vLinkified = {
    mounted: (el: HTMLElement, binding: DirectiveBinding) => {
      el.innerHTML = linkifyHtml(el.innerHTML, binding.value)
    }
  }

  const textToCopy = computed(
    () => `${doc.value.genre || ''}${doc.value.genre && doc.value.headline ? ' | ' : ''}${doc.value.headline}
${doc.value.published.toLocaleDateString()} - ${doc.value.published.toLocaleTimeString()}
${doc.value.source || ''}${doc.value.source && doc.value.city ? ' • ' : ''}${doc.value.city || ''}${doc.value.city && doc.value.creator ? ' • ' : ''}${doc.value.creator || ''}
${doc.value.paragraphs.join('\n')}
${doc.value.id}`
  )
</script>

<template>
  <article
    ref="article"
    class="bg-base-100 print:bg-white max-w-[var(--document-max-width)] overflow-y-auto overscroll-contain pr-[var(--document-padding-left)] pb-[100px] pl-[var(--document-padding-left)] shadow-md sm:pr-[var(--document-padding-right)]"
  >
    <DockComponent
      :hide="!showNavbar"
      class="sm:hover:bg-base-200 max-w-[var(--document-max-width)] sm:w-full print:hidden"
      @close="emit('close')"
    >
      <template
        v-if="isSmallerMd"
        #left
        ><CopyButton
          :text="textToCopy"
          :title="t('document.copy')"
      /></template>
      <template
        v-if="isSmallerMd"
        #center
        ><WebShare
          :headline="doc.headline"
          :text="textToCopy"
          :title="t('document.share')"
      /></template>
    </DockComponent>
    <div
      v-if="doc.genre"
      class="badge badge-neutral mt-5 rounded-xs font-semibold uppercase"
    >
      <RouterLink
        :to="`/genre/${doc.genre}`"
        class="text-inherit"
      >
        {{ doc.genre }}
      </RouterLink>
    </div>
    <h1
      class="mb-5 text-3xl leading-7 font-bold tracking-tight sm:text-5xl sm:leading-11"
      :class="doc.genre ? 'mt-4' : 'mt-5'"
    >
      {{ doc.headline }}
    </h1>
    <span class="sr-only">&nbsp;</span>
    <DocumentDetails
      :doc="doc"
      class="mb-1"
    />

    <MediaGallery
      v-if="doc.type === 'multimedia'"
      :key="doc.id"
      :medias="doc.medias"
      class="mt-4 mb-2"
    />
    <div class="sm:flex sm:flex-row-reverse print:block">
      <main class="pt-4 sm:w-3/4 print:w-full">
        <template
          v-for="(p, i) in doc.paragraphs"
          :key="`paragraph-${i}`"
        >
          <h2
            v-if="p.match(/^\s?[-–]\s(.*)\s[-–]\s?$/)"
            :key="`subtitle-${i}`"
            class="mt-5 mb-4 text-xl font-semibold"
          >
            <highlighter
              highlight-class-name="bg-secondary/30"
              :search-words="searchTerms"
              :auto-escape="true"
              :text-to-highlight="getSubtitle(p)"
            />
          </h2>
          <p
            v-else
            :key="`paragraph-${i}`"
            class="mb-2 text-lg"
          >
            <highlighter
              highlight-class-name="bg-secondary/30 dark:bg-secondary dark:text-base-content"
              v-linkified
              :search-words="searchTerms"
              :auto-escape="true"
              :text-to-highlight="p"
            />
          </p>
        </template>

        <details
          v-if="doc.revision && doc.revision >= 1"
          class="bg-base-100 border-base-300 collapse border print:hidden"
          :class="{
            'collapse-arrow': doc.advisory
          }"
        >
          <summary class="collapse-title font-semibold">
            {{ t('document.version') }} {{ doc.revision }}
          </summary>
          <div
            v-if="doc.advisory"
            class="collapse-content text-sm"
          >
            {{ doc.advisory }}
          </div>
        </details>
      </main>

      <aside class="py-5 pr-2 sm:w-1/4 print:w-full">
        <SlugsComponent
          v-if="doc.slugs"
          :slugs="doc.slugs"
          :lang="doc.lang"
          type="slug"
          class="mb-5"
        />
        <div class="join hidden md:block">
          <CopyButton
            :title="t('document.copy')"
            :text="textToCopy"
            class="btn btn-primary"
          >
            {{ t('document.copy') }}
          </CopyButton>
          <WebShare
            :headline="doc.headline"
            :text="textToCopy"
            class="btn btn-primary"
          >
            {{ t('document.share') }}
          </WebShare>
        </div>
        <p
          v-if="route.params.docId"
          class="hidden print:block"
        >
          ID : {{ route.params.docId }}
        </p>
      </aside>
    </div>

    <RelatedDocuments
      :doc="doc"
      :size="3"
      class="mt-5 select-none"
    />
  </article>
</template>

<style lang="css" scoped>
  article {
    -webkit-overflow-scrolling: touch;
  }
</style>
